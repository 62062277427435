import Paho from 'paho-mqtt';
import store from '@/store'
import { localUser } from '@/utils/AuthService'
import { qrcodeRoute } from '@/store'

export function getTopic(userId) {
    if (userId) {
        return 'user/' + userId.toString() + '/#';
    } else {
        console.log('invalid userId: ' + userId);
        return '';
    }
}

const uid = Date.now().toString() + Math.random().toString(36).substring(7);
function getConfig() {
    let config = {
        port: 8083,
        usessl: true,
        path: '/wss',
        username: 'stackswap',
        password: 'SSbox@PassW0rd'
    }
    switch (location.hostname) {
        case 'bboxlocker.com': // Production
            config.url = location.hostname
            break
        default: // staging: iboxmessage.com
            config.url = 'bboxlocker.com'
            break
    }
    return config
}
const config = getConfig()
const client = new Paho.Client(config.url, config.port, config.path, uid);

export const MQTT_ACTION = {
    PUTIN_ACTION: 2,
    PUTIN_FINISH: 3,
    TAKEOUT_ACTION: 4,
    RETRIEVE_ACTION: 8,
    CONTROL_ERROR: 16,
    NOTIFY: 64
}

export const TRANS_TYPE = {
    DELIVERY: 1,
    LEAVE: 2,
    LONG_LEAVE: 3,
    STOREHOST: 4,
    WASHCLIENT: 5,
    WASHCLERK: 7,
    APICLIENT: 8,
    DOCSCLIENT: 9,
    DOCSCLERK: 11,
}

// called when the client connects
function onConnect() {
    // Once a connection has been made, make a subscription and send a message.
    let user_id = store.getters.user.id
    let topic = getTopic(user_id);
    console.log('user:' + user_id +' onConnect, subscribe topic: ' + topic);
    subscribe(topic);
    subscribe('user/broadcast'); // for receiving the broadcast message

    // Report connection event to Server
    publish('server/web/connection', {
        user_id: user_id,
        client_id: store.getters.clientId
    }, 1);

    // Watch store.getters.user.id change
    store.watch(
        (state, getters) => getters.user.id,
        (newValue, oldValue) => {
            console.log(`change subscribe topic from user ${oldValue} to user ${newValue}`);
            unsubscribe(getTopic(oldValue));
            subscribe(getTopic(newValue));
        }
    )
}

//called when the client connection fails
function onFailure() {
    console.log('onFailure')
    if (navigator.onLine) {
        //store.commit('setSnackBar', {message: 'Network Failed', color: 'error'})
        console.log('onFailure reload .. '+location.href)
        if (localUser()) {
            console.log('[mqtt onFailure] reload 1 secs')
            setTimeout(window.location.reload(), 1000)
        } else {
            console.log('[mqtt onFailure] pass 2 secs')
            setTimeout(connect, 2000)
        }
    } else {
        console.log('[mqtt onFailure] offline pass 2 secs')
        setTimeout(connect, 2000)
    }
    console.log(store.getters.user.id)
}

// called when the client loses its connection
function onConnectionLost(resp) {
    console.log('onConnectionLost');
    console.log('errorCode: ' + resp.errorCode);
    console.log('errorMessage: ' + resp.errorMessage);
    if (navigator.onLine) {
        //store.commit('setSnackBar', {message: 'Network Lost Error', color: 'error'})
        if (localUser()) {
            console.log('[mqtt onConnectionLost] reload 1 secs')
            setTimeout(window.location.reload(), 1000)
        } else {
            console.log('[mqtt onConnectionLost] pass 2 secs')
            setTimeout(connect, 2000)
        }
    } else {
        console.log('[mqtt onConnectionLost] offline pass 2 secs')
        setTimeout(connect, 2000)
    }
    console.log(store.getters.user.id)
}

function handleMQTTMessage(topic, payload) {
    console.log('handleMQTTMessage');
    if (payload.client_id === store.getters.clientId) store.commit('setMQTTMessage', { topic: topic, payload: payload })
    else console.log('ignore topic and payload:', topic, payload)
}

function handleBoxStatus(payload) {
    console.log('handleBoxStatus');
    if (payload.client_id === store.getters.clientId) store.commit('setBoxStatusResult', payload)
    else console.log('ignore box_status payload:', payload)
}

export function handleSelectBox(payload) {
    console.log('handleSelectBox');
    if (payload.client_id === store.getters.clientId) {
        if (!store.getters.user.isGuest) {
            store.commit('setTargetBoxId', payload.box_id);
            store.commit('setLastMessageTime', payload.time);
        } else if (payload.box_home === undefined || payload.box_home === null || qrcodeRoute(payload.box_home) === false) {
            console.log('home:'+payload.box_home+", box_id:"+payload.box_id+",time:"+payload.time)
            store.commit('setTargetBoxId', payload.box_id);
            store.commit('setLastMessageTime', payload.time);
        } else console.log('[wrong client page home] change page home payload:', payload)
    } else console.log('ignore selectbox payload:', payload)
}

function handleTransReport(payload) {
    console.log('handleTransReport')
    if (payload.client_id === store.getters.clientId) store.commit('setControlResult', payload.trans)
    else console.log('ignore trans_report payload:', payload)
}

function handleControlReport(payload) {
    console.log('handleControlReport');
    if (payload.box_id === store.getters.targetBoxId && payload.client_id === store.getters.clientId) {
        store.commit('setControlResult', payload.doors)
        localStorage.setItem('slave', payload.wrong_slave)
    } else console.log('ignore control_report(door_status) payload:', payload)
}

function handleDoorCheckReport(payload) {
    console.log('handleDoorCheckReport');
    if (payload.box_id === store.getters.targetBoxId && payload.client_id === store.getters.clientId) {
        store.commit('setDoorCheckResult', payload.doors)
        localStorage.setItem('slave', payload.wrong_slave)
        console.log('doors:'+payload.doors+', wrong_slave:'+payload.wrong_slave)
    } else console.log('ignore door_check_report payload:', payload)
}

function handleBoxSettingReport(payload){
    console.log('handleSettingReport')
    if (payload.client_id === store.getters.clientId) store.commit('setBoxSettingResult', payload);
    else console.log('ignore box_setting_report payload:', payload)
}

function getTopics() {
    let prefix = 'user/' + store.getters.user.id
    return [prefix, {
        [prefix + '/selectbox']: handleSelectBox,
        [prefix + '/trans/report']: handleTransReport,
        [prefix + '/doors/status']: handleControlReport,
        [prefix + '/setting/report']: handleBoxSettingReport,
        [prefix + '/box/status']: handleBoxStatus,
        [prefix + '/doors/status_check']: handleDoorCheckReport
    }]
}

// called when a message arrives
function onMessageArrived(msg) {
    console.log('onMessageArrived:', msg);
    let payload = JSON.parse(msg.payloadString);
    if (msg.duplicate) {
        if (store.getters.mqttPayload) {
            if (payload.time === store.getters.mqttPayload.time && payload.client_id === store.getters.mqttPayload.client_id) {
                console.log('mqtt message 重複了')
                return
            }
        }
    }
    if (store.getters.lastMessageTime > payload.time) {
        console.log('expired message, time: ' + payload.time + ', lastMessageTime: ' + store.getters.lastMessageTime);
        return;
    }
    if (store.getters.reopenTimeLimit === false) {
        store.commit('setMQTTPayload', payload)
    }
    let [prefix, topics] = getTopics()
    if (msg.topic in topics) topics[msg.topic](payload)
    else if (msg.topic.startsWith(prefix)) handleMQTTMessage(msg.topic, payload)
    else console.log('ignore message', msg.topic, payload)
}

console.log('start MQTT connection');

// set callback handlers
client.onConnectionLost = onConnectionLost;
client.onMessageArrived = onMessageArrived;

export function publish(topic, payload, qos=0, retained=false) {
    console.log('publish', topic, payload, qos, retained)
    if (topic && payload) {
        let message = new Paho.Message(JSON.stringify(payload));
        message.destinationName = topic;
        message.qos = qos;
        message.retained = retained;
        client.send(message);
    } else {
        console.log('No MQTT connection or invalid message, skip topic: ' + topic);
    }
}

export function connect() {
    console.log('start MQTT connection');

    try {
        // connect the client
        client.connect({
            onSuccess: onConnect,
            onFailure: onFailure,
            userName: config.username,
            password: config.password,
            useSSL: config.usessl
        });
    } catch(err) {
        console.log('[Mqtt connect fail]'+ err)
        window.location.reload()
    }
}

export function unsubscribe(topic) {
    if (topic) {
        console.log('unsubscribe topic: ' + topic);
        client.unsubscribe(topic);
    } else {
        console.log('invalid topic: ' + topic);
    }
}

export function subscribe(topic) {
    if (topic) {
        console.log('subscribe topic: ' + topic)
        //更改訂閱時 qos 1
        client.subscribe(topic, { qos: Number(1) });
    } else {
        console.log('invalid topic: ' + topic);
    }
}
