import axios from 'axios'
import { HostUrl } from '@/store'
import { getAxiosConfig, PWA_TOKEN } from '@/utils/AuthService'

export const VERSION_KEY = 'version'

function checkAndUpdate(latest) {
    let current = localStorage.getItem(VERSION_KEY)
    console.log('latest: ' + latest + ', current: ' + current)
    if (latest && (!current || latest !== current)) {
        localStorage.setItem(VERSION_KEY, latest)
        setTimeout(window.location.reload(), 1000)
    }
}

export function upgrade() {
    let url = HostUrl + '/api/pwa/latest'
    axios.get(url, getAxiosConfig(PWA_TOKEN))
        .then(function (resp) {
            if (resp.data.code === 0) checkAndUpdate(resp.data.version)
            else console.log('Get Latest Version Failed', resp.data)
        })
        .catch(function (error) {
            console.log('Get Latest Version Error', error)
        })
}