import Vue from 'vue'
import './plugins/vuetify'
import App from './App.vue'
import router from './router'
import store from './store'
import './registerServiceWorker'
import i18n from './i18n'
//import '../node_modules/material-design-icons-iconfont/dist/material-design-icons.css'

Vue.config.productionTip = false

function memoPage() {
    let fullurl = location.href
    let home = parseInt(localStorage.getItem('pagehome'))
    //let urllen = location.host.length
    console.log('main:'+fullurl)
    //console.log('urllen:'+location.host.length+', fullurllen:'+fullurl.length)
    //let current = localStorage.getItem(PAGEHOME_KEY)
    if(fullurl.indexOf('washhome') !== -1 && home !== 2) {
        //console.log(2)
        store.commit('setPageHome', 2)
        localStorage.setItem('lineState', 'washhome')
    } else if (fullurl.indexOf('traveldocshome') !== -1 && home !== 3) {
        store.commit('setPageHome', 3)
        localStorage.setItem('lineState', 'traveldocshome')
    } else if (fullurl.indexOf('storehome') !== -1 && home !== 1) {
        //console.log(1)
        store.commit('setPageHome', 1)
        localStorage.setItem('lineState', 'storehome')
    } else {
        //console.log(0)
        store.commit('setPageHome', 0)
        localStorage.setItem('lineState', '')
    }
}

router.beforeEach((to, from, next) => {
    console.log(location.href)
    if (location.href.indexOf('washhome/go') !== -1)
        localStorage.setItem('unloginflag', 'wash-takeout')
    else if (location.href.indexOf('traveldocshome/go') !== -1)
        localStorage.setItem('unloginflag', 'traveldocs-takeout')
    console.log(from.path)
    let language = to.params.lang
    console.log(language)
    //console.log(navigator.userAgent)
    //console.log(navigator.userAgent.indexOf('Build/MMB29') !== -1)
    if (!language) {
        language = i18n.locale
        memoPage()
    }
    i18n.locale = language
    next()
})

new Vue({
    router,
    store,
    i18n,
    render: h => h(App)
}).$mount('#app')

// Build MQTT connection
import { connect } from './utils/MQTTClient'
connect()
