<template>
    <v-dialog v-model="show" persistent no-click-animation width="250">
        <v-card color="cyan" dark>
            <v-card-text>
                <p>{{msg}}</p>
                <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
            </v-card-text>
            <v-card-actions v-if="closeable">
                <v-btn block flat @click="close">取消</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import store from '@/store'

export default {
    name: 'waiting-dialog',
    props: {
        show: {
            type: Boolean,
            required: true
        },
        msg: {
            type: String,
            required: true
        },
        errMsg: {
            type: String,
            default: ''
        },
        timeout: {
            type: Number,
            default: 10000
        },
        closeable: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            timeoutId: null
        }
    },
    watch: {
        show (val) {
            if (!val) clearTimeout(this.timeoutId)
            else {
                var vm = this
                vm.timeoutId = setTimeout(() => {
                    if (vm.show) {
                        if (vm.errMsg) {
                            let errMsg = vm.errMsg
                            if (errMsg.indexOf('TIMEOUT') !== -1) {
                                store.commit('setSnackBar', {message: errMsg, color: 'error'})
                            }
                            else
                                store.commit('setSnackBar', {message: errMsg, color: 'warning'})
                        }
                        vm.close()
                    }
                }, vm.timeout)
            }
        }
    },
    methods: {
        close() {
            this.$emit('update:show', false)
            this.$emit('close')
        }
    }
}
</script>

<style scoped>
</style>
