<script>
import axios from 'axios'
import store, { HostUrl, HostEnv, CurrentUri } from '@/store'
import i18n from '@/i18n'
import { getAxiosConfig, PWA_TOKEN } from "@/utils/AuthService"
import { HOMETYPES } from '@/utils/utils'

export default {
    data() {
        return {
        }
    },
    methods: {
        isCodeLogin(){
            if (window) {
                const urlParams = new URLSearchParams(window.location.search);
                if(urlParams.has("code")){
                    return true;
                }
            }
            return false;
        },
        async googleLogin(){
            if (window) {
                const urlParams = new URLSearchParams(window.location.search);
                if(urlParams.has("code")){
                    let out = null
                    try {
                        const info = {
                            'code':urlParams.get("code"),
                            'scope': 'email profile https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile openid',
                            'authuser':0,
                            'prompt':"consent"
                        }

                        let payload = {
                            'client_id': store.getters.clientId,
                            'code': urlParams.get("code"),
                            'home': CurrentUri,
                            'env': HostEnv,
                            'info': info,
                            'browser': navigator.userAgent
                        }
                        let url = HostUrl + '/api/user/google_login'
                        let response = await axios.post(url, payload, getAxiosConfig(PWA_TOKEN));
                        if (response.data.code == 0) {
                            store.commit('setUserId', response.data.profile.id)
                            store.dispatch('changeUser', {
                                profile: response.data.profile,
                                token: response.data.token
                            })
                            store.dispatch('afterLogin')
                            out = false
                        } else {
                            store.commit('setUserId', 0)
                            store.commit('setSnackBar', {
                                message: i18n.t('googleLogin.google-login-failed'),
                                color: 'error'
                            })
                            out = true
                        }
                    } catch (error) {
                        store.commit('setSnackBar', {
                            message: i18n.t('googleLogin.google-login-failed'),
                            color: 'error'
                        })
                        out = true
                    }
                    if(out) {
                        let page = localStorage.getItem('lineState')
                        if (page === 'storehome')
                            this.$store.commit('setPageHome', HOMETYPES.STORE)
                        else if (page === 'washhome')
                            this.$store.commit('setPageHome', HOMETYPES.WASH)
                        this.$router.push(`/${i18n.locale}/login`)
                    }
                }
            }
        }
    }
}
</script>
