<template>
    <v-snackbar v-model="show" :color="color" :auto-height="true" :timeout=10000 top>
        {{message}}
        <v-spacer></v-spacer>
        <v-btn flat small @click.native="show = false">
            <v-icon right>close</v-icon>
        </v-btn>
    </v-snackbar>
</template>

<script>
export default {
    name: 'SnackBar',
    data() {
        return {
            show: false,
            message: '',
            color: 'info'
        }
    },
    mounted() {
        this.$store.watch(
            (state, getters) => getters.snackBar,
            (newValue) => {
                this.show = true;
                this.message = newValue.message;
                this.color = newValue.color;
            }
        )
    }
}
</script>
