<template>
    <v-app>
        <!-- 工具列 -->
        <AppNavigation />
        <!-- 根據 Vuex store 中的 snackBar 內容跳出提示 -->
        <SnackBar />
        <!-- 當有新版出現跳出提醒 -->
        <update-notifier />
        <v-content transition="slide-x-transition">
            <transition>
                <!-- 盡量 reuse 已生成 Vue component, 避免出現多一個頁面出現多次問題 -->
                <keep-alive>
                    <!-- 透過 Vue-Router 切換 component -->
                    <router-view :key="$route.path"></router-view>
                </keep-alive>
            </transition>
        </v-content>
    </v-app>
</template>

<script>
import AppNavigation from "@/components/AppNavigation"
import SnackBar from "@/components/SnackBar"
import UpdateNotifier from '@/components/UpdateNotifier'
import smoothscroll from 'smoothscroll-polyfill'

//為了解決safari瀏覽器平順滑動
smoothscroll.polyfill()

export default {
    name: "App",
    components: { AppNavigation, SnackBar, UpdateNotifier },
    mounted() {
        this.$store.dispatch('facebookInit')
    }
}
</script>
