<script>
const TIMEOUT = 25000
export default {
    data() {
        return {
            dialog: {
                show: false,
                msg: '',
                err: '',
                timeout: TIMEOUT,
                closeable: false
            }
        }
    },
    methods: {
        showDialog(msg, err, timeout=TIMEOUT, closeable=false) {
            this.dialog.msg = msg
            this.dialog.err = err
            this.dialog.timeout = timeout
            this.dialog.closeable = closeable
            this.dialog.show = true
        },
        closeDialog() {
            this.dialog.show = false
        }
    }
}
</script>
