<template>
    <v-select
        label="Language"
        :items="names"
        item-value="value"
        item-text="name"
        return-object
        solo
        dense
        background-color="blue darken-4"
        v-on:change="setLocale"
        class="selectLang"
        prepend-inner-icon="language"
    >
        <template slot='selection' slot-scope='{ item }'>
            {{ item.text }} 
        </template>
        <template slot='item' slot-scope='{ item }'>
            {{ item.text }}
        </template>
    </v-select>
</template>

<script>
import store from '@/store'

export default {
  data: () => ({
    names:[
      {value: 'tw', text: '中文'},
      {value: 'en', text: 'English'}
    ]
  }),
  methods: {
    setLocale(locale) {
        console.log(locale)
        this.$i18n.locale = locale.value
        this.$router.push({
            params: {
                lang: locale.value
            }
        })
        console.log('loginRedirect 3'+store.getters.loginRedirect)

        // this.$store.commit('', SideBtnType.Back);
    }
  }
}   
</script>

<style scoped>
.selectLang .v-label {
  max-width: 100px;
  margin-left: 10px;
  margin-top: 8px;
  white-space: nowrap;
  font-weight: bold;
}

/* .v-select-list .v-list .v-input__slot { margin-bottom: 0 !important; } */
</style>