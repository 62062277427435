<script>
import store from '@/store'

export default {
    data() {
        return {
            userSynced: false,
            userSyncedWatcher: null
        }
    },
    methods: {
        watchProfile() {
            this.userSyncedWatcher = store.watch(
                (state, getters) => getters.user.synced,
                (val) => {
                    if (val) this.userSynced = val
                }
            )
        }
    },
    activated() {
        this.watchProfile()
    },
    deactivated() {
        // unwatch userSynced 
        if (this.userSyncedWatcher) this.userSyncedWatcher()
    }
}
</script>
