
export const CHECK_DOOR_TIMEOUT = 30000
export const RESET_DOOR_TIMEOUT = 35000
export const RESET_SERVER_TIMEOUT = 20000
export const HOMETYPES = {
    LOCKER: 0,
    STORE: 1,
    WASH: 2,
    TRAVELDOCS: 3
}

export function formatDatetime(d, showSecs=true) {
    let year = d.getFullYear(),
        month = d.getMonth() + 1,
        date = d.getDate(),
        hour = d.getHours(),
        minute = d.getMinutes()
    if (month < 10) month = '0' + month
    if (date < 10) date = '0' + date
    if (hour < 10) hour = '0' + hour
    if (minute < 10) minute = '0' + minute
    let timeStr = [year, month, date].join('-') + ' ' + [hour, minute].join(':')
    if (showSecs) {
        let second = d.getSeconds()
        if (second < 10) second = '0' + second
        timeStr = [timeStr, second].join(':')
    }
    return timeStr
}

export function convertSecToTimeStr(totalSec) {
    let min = Math.floor(totalSec / 60)
    let sec = totalSec % 60
    if (sec < 10) sec = '0' + sec
    return [min, sec].join(':')
}

export function parseBrowser() {
    if (location.href.indexOf('bboxlocker') === -1) {
        return 'test station'; // 測試站的linepay付款 還是維持網頁型態，不跳到line app
    } else if (navigator.userAgent.indexOf("Line") != -1 && navigator.userAgent.indexOf("Mac OS X") != -1) {
        return 'MOBILE';
    } else if((navigator.userAgent.indexOf("Opera") || navigator.userAgent.indexOf('OPR')) != -1 ) {
        return 'Opera';
    } else if(navigator.userAgent.indexOf("Chrome") != -1  && navigator.userAgent.indexOf("Android") != -1 && navigator.userAgent.indexOf("Mobile") != -1) {
        return 'MOBILE';
    } else if(navigator.userAgent.indexOf("Safari") != -1) {
        return 'Safari';
    } else if(navigator.userAgent.indexOf("Firefox") != -1 ){
        return 'Firefox';
    } else if((navigator.userAgent.indexOf("MSIE") != -1 ) || (!!document.documentMode == true )) {
        return 'IE';
    } else {
        return 'Unknown';
    }
}