<template>
    <v-dialog persistent v-model="show" width="250">
        <v-card dark>
            <v-card-title class="title">版本更新</v-card-title>
            <v-card-text class="subheading pt-0 pb-0"><span v-html="message"></span></v-card-text>
            <v-card-actions class="justify-center">
                <v-btn block class="subheading" color="primary" @click="refresh">開始使用</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import store from '@/store'
import { VERSION_KEY } from '@/utils/Updater'

export default {
    name: 'update-notifier',
    data() {
        return {
            show: false,
            version: ''
        }
    },
    computed: {
        message() {
            return '新版 ' + this.version + ' 已完成下載'
        }
    },
    methods: {
        refresh() {
            this.show = false
            store.commit('setUpdateAvailable', false)
            window.location.reload()
        }
    },
    mounted() {
        store.watch(
            (state, getters) => getters.updateAvailable,
            (val) => {
                if (val) {
                    this.show = true
                    this.version = localStorage.getItem(VERSION_KEY)
                }
            }
        )
    }
}
</script>