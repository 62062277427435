<template>
    <v-card flat>
        <v-card-text>
                {{ $t('linePay.use-line-pay') }} <strong>{{ $t('linePay.login-close-tab') }}</strong> {{ $t('linePay.continue-complete-payment') }}
            <v-alert :value="message" type="warning">{{ message }}</v-alert>
            <!-- <v-subheader class="subheading">
                使用 Line Pay 付款 會跳轉至用戶的 Line app 付款
                <v-btn v-if="card" small color="info" @click="updateCard">{{editCardBtn}}</v-btn>
            </v-subheader> -->
            <!-- <v-card flat v-show="editCard" subheader>
                <v-card-text class="mt-0 pt-0">
                    <div>
                        <span class="subheading">{{ $t('directPay.card-number') }}</span>
                        <div class="tpfield" id="card-number"></div>
                        <span class="subheading">{{ $t('directPay.expiry-date') }}</span>
                        <div class="tpfield" id="card-expiration-date"></div>
                        <span class="subheading">{{ $t('directPay.three-digits') }}</span>
                        <div class="tpfield" id="card-ccv"></div>
                    </div>
                </v-card-text>
            </v-card> -->
            <!-- <v-list v-show="!editCard" subheader>
                <v-list-tile two-line>
                    <v-list-tile-content>
                        <v-list-tile-title>
                            {{ $t('directPay.card-number') }}&nbsp;{{cardNo()}}
                        </v-list-tile-title>
                    </v-list-tile-content>
                    <v-list-tile-action>
                        <v-chip v-if="card" color="green" text-color="white" class="subheading">
                            {{card.type}}
                        </v-chip>
                    </v-list-tile-action>
                </v-list-tile>
            </v-list> -->
            <!-- <v-divider></v-divider>
            <v-subheader class="subheading">{{ $t('directPay.password-validation') }}</v-subheader>
            <v-card flat subheader>
                <v-card-text class="mt-0 pt-0">
                    <v-text-field outline clearable required single-line
                        :label="$t('directPay.user-pass')"
                        v-model="password"
                        name="password"
                        :append-icon="showPass ? 'visibility_off' : 'visibility'"
                        :type="showPass ? 'text' : 'password'"
                        :rules="passwordRules"
                        @click:append="showPass = !showPass" 
                    ></v-text-field>
                </v-card-text>
            </v-card> -->
        </v-card-text>
        <waiting-dialog :show.sync="dialog.show" :msg="dialog.msg" :err-msg="dialog.err"></waiting-dialog>
    </v-card>
</template>

<script>
// import axios from 'axios'
// import store from '@/store'
import WaitingDialog from '@/components/WaitingDialog'
import WaitDialogControl from '@/mixins/WaitDialogControl'
// import PasswordMixin from '@/mixins/PasswordMixin'
// import { HostUrl } from '@/store'
// import { getAxiosConfig } from '@/utils/AuthService'
import i18n from '@/i18n'
// import { getAxiosConfig } from '@/utils/AuthService'

export const LinePayType = 2 // 配合 Backend 定義
export default {
    name: 'line-pay',
    props: {
        enable: {
            type: Boolean,
            required: true,
        },
        message: {
            type: String,
            default: ''    
        }
    },
    data() {
        return {
            // editCard: false,
            // editCardBtn: i18n.t('directPay.change'),
            // isCardValid: false,
            // card: null
        } 
    },
    components: { WaitingDialog },
    mixins: [ WaitDialogControl ],
    watch: {
        enable() {
            console.log('this.enable', this.enable)
            if (this.enable) this.submit()
            // if (this.enable) this.init()
            else this.$emit('payinfo', null)
        },
        // isChoosed() {
        //     this.submit()
        // }
        // isCardValid() {
        //     console.log('this.isValid', this.isValid)

        //     if (this.isValid) this.submit()
        //     else this.$emit('payinfo', null)
        // },
        // password() {
        //     console.log('this.isValid', this.isValid)

        //     if (this.isValid) this.submit()
        //     else this.$emit('payinfo', null)
        // }
    },
    computed: {
        // isValid() {
        //     // console.log('this.isCardValid', this.isCardValid)
        //     console.log('this.password', this.password)
            
        //     return this.password
        // }
        // isValid() {
        //     return this.isCardValid && this.password
        // }
    },
    methods: {
        // init() {
            // this.initPasswordField()
            // this.editCard = false
            // this.editCardBtn = i18n.t('directPay.change')
            // this.getCardInfo()
            // this.isCardValid = this.card
        // },
        // onUpdate(update) {
        //     this.isCardValid = update.canGetPrime
        // },
        // setUpTPFields() {
            // eslint-disable-next-line
            // TPDirect.setupSDK(13942, 'app_El9r6wUxmqgPfxW1pVYkIiDri1lBqGctvEqVhEu0mwUKmjIs85rY1k1iTru0', 'sandbox')
            // let fields = {
            //     number: {
            //         element: '#card-number',
            //         placeholder: '#### #### #### ####'
            //     },
            //     expirationDate: {
            //         element: '#card-expiration-date',
            //         placeholder: 'MM / YY'
            //     },
            //     ccv: {
            //         element: '#card-ccv',
            //         placeholder: '###'
            //     }
            // }
            // eslint-disable-next-line
            // TPDirect.card.setup({
            //     fields: fields,
            //     styles: {
            //         'input': {'font-size': '20px'},
            //         '.valid': { 'color': 'green' },
            //         '.invalid': { 'color': 'red' }
            //     }
            // })
            // eslint-disable-next-line
            // TPDirect.card.onUpdate(this.onUpdate)
        // },
        // getCardInfo() {
        //     var self = this
        //     axios.get(HostUrl + '/api/payment/direct', getAxiosConfig())
        //         .then(function (response) {
        //             if (response.data.code === 0) {
        //                 self.card = response.data.card
        //                 // self.isCardValid = true
        //             } else if (response.data.code === 503) {
        //                 // self.editCard = true
        //             } else {
        //                 store.commit('setSnackBar', {
        //                     message: response.data.message,
        //                     color: 'error'
        //                 })
        //             }
        //         })
        //         .catch(function () {
        //             store.commit('setSnackBar', {
        //                 message: i18n.t('directPay.fail-get-credit-info'),
        //                 color: 'error'
        //             })
        //         })
        // },
        payByPrime() {
            // this.showDialog(i18n.t('directPay.charge'), i18n.t('directPay.debit-failed'))
            // try {
                // var self = this
                // eslint-disable-next-line
                // TPDirect.linePay.getPrime(function(result){

                    // console.log("result : " , result)
                    
                    // if (result.status !== 0) {
                    //     store.commit('setSnackBar', {
                    //         message: result.msg,
                    //         color: 'error'
                    //     })
                    // } else {
                        // console.log('payinfo success')

                        //需要 先將 前端拿到的prime 傳去後端再由後端傳 payload (格式如下，需傳要從LINE APP跳轉回來的我們的網址) 去tappay server，tappay 審核過後再回傳東西給後端，後端再傳跳轉網址給前端
                        //官網 LINE Pay 前端範例： https://github.com/TapPay/tappay-web-example/tree/master/Line_Pay
                        //後端 node js 範例 https://yu-jack.github.io/2017/09/23/tappay-payment/
                        
                        //LinePay component 中的 v-model="password" 變動時有更改 passwordMixin 的 password ，然後 LinePay 中的 自動偵測 watch 中的 password 改變時 呼叫 isValid 有過就
                        //呼叫 submit()，submit 呼叫 payByPrime，取得prime後將prime傳給後端server，等後端server回傳跳轉網址後，將帶有跳轉網址的payinfo emit給父組件 PaymentSelector 如下：
                        // self.$emit('payinfo', {
                        //     type: LinePayType, --> 配合backend定義
                        //     prime: result.prime,
                        //     clientip: result.client_ip,
                        //     password: self.encryptedPassword()
                        // })
                 
                        //PaymentSelector 收到自 LinePay傳來的 payinfo 後觸發 updatePayInfo 將 PaymentSelector data裡的payinfo更新成傳上來的payinfo 
                        //（ payinfo預設值為 null ），此時PaymentSelector裡的computed自動偵測若payinfo不等於null則將isValid回傳true ==> 支付按鈕亮起
                        //如果payinfo裡的type = ElectronicMoneyType 則直接呼叫付款


                        // const payload = {
                        //     "partner_key": "partner_wv7JfyuAjDNfADnina24UbMX27x3tE8Nzuyfa5qtbWP12yENKwHAxqRy",
                        //     "prime": `${result.prime}`,
                        //     "amount": "1",
                        //     "merchant_id": "53558292_00001",
                        //     "details": "Some item",
                        //     "cardholder": {
                        //         "phone_number": "+886923456789",
                        //         "name": "王小明",
                        //         "email": "LittleMing@Wang.com",
                        //         "zip_code": "100",
                        //         "address": "台北市天龍區芝麻街1號1樓",
                        //         "national_id": "A123456789"
                        //     },
                        //     "result_url": {
                        //         "frontend_redirect_url": "http://192.168.0.12:8080/tw/putin",
                        //         "backend_notify_url": "http://192.168.0.12:8080/tw/putin"
                        //     }
                        // }

                        // fetch('https://sandbox.tappaysdk.com/tpc/payment/pay-by-prime', {
                        //     method: 'POST',
                        //     headers: {
                        //         'Content-Type': 'application/json'
                        //     },
                        //     body: JSON.stringify(payload)
                        // })
                        //     .then((response) => {
                        //         console.log('response', response)
                        //     })
                        //     .catch((error) => {
                        //         console.log('error', error)
                        //     })

                        // axios.post('https://sandbox.tappaysdk.com/tpc/payment/pay-by-prime', payload, getAxiosConfig())
                        //     .then(function(response){
                        //         console.log('response', response)
                        //     })

                        this.$emit('payinfo', {
                            type: LinePayType,
                            // prime: result.prime,
                            // clientip: result.client_ip,
                            locale: i18n.locale,
                            // cs_key: result.cs_key,
                            // payment_url: result.payment_url
                            // password: self.encryptedPassword()
                        })
                    // }

                // })
            // } finally {
            //     this.closeDialog()
            // }


            // try {
            //     // eslint-disable-next-line
            //     let tappayStatus = TPDirect.card.getTappayFieldsStatus()
            //     if (tappayStatus.canGetPrime) {
            //         var self = this
            //         // eslint-disable-next-line
            //         TPDirect.card.getPrime(function(result) {
            //             if (result.status !== 0) {
            //                 store.commit('setSnackBar', {
            //                     message: result.msg,
            //                     color: 'error'
            //                 })
            //             } else {
            //                 self.$emit('payinfo', {
            //                     type: DirectPayType,
            //                     prime: result.card.prime,
            //                     clientip: result.clientip,
            //                     card_identifier: result.card_identifier,
            //                     password: self.encryptedPassword()
            //                 })
            //             }
            //         })
            //     } else {
            //         store.commit('setSnackBar', {
            //             message: i18n.t('directPay.credit-invalid'),
            //             color: 'error'
            //         })
            //     }
            // } finally {
            //     this.closeDialog()
            // }
        },
        // payByPrime() {
        //     this.showDialog(i18n.t('directPay.charge'), i18n.t('directPay.debit-failed'))
        //     try {
        //         // eslint-disable-next-line
        //         let tappayStatus = TPDirect.card.getTappayFieldsStatus()
        //         if (tappayStatus.canGetPrime) {
        //             var self = this
        //             // eslint-disable-next-line
        //             TPDirect.card.getPrime(function(result) {
        //                 if (result.status !== 0) {
        //                     store.commit('setSnackBar', {
        //                         message: result.msg,
        //                         color: 'error'
        //                     })
        //                 } else {
        //                     self.$emit('payinfo', {
        //                         type: DirectPayType,
        //                         prime: result.card.prime,
        //                         clientip: result.clientip,
        //                         card_identifier: result.card_identifier,
        //                         password: self.encryptedPassword()
        //                     })
        //                 }
        //             })
        //         } else {
        //             store.commit('setSnackBar', {
        //                 message: i18n.t('directPay.credit-invalid'),
        //                 color: 'error'
        //             })
        //         }
        //     } finally {
        //         this.closeDialog()
        //     }
        // },
        // payByToken() {
        //     this.$emit('payinfo', {
        //         type: DirectPayType,
        //         card_id: this.card.id,
        //         password: this.encryptedPassword()
        //     })
        // },
        // cardNo() {
        //     if (this.card){
        //         return '**** **** **** ' + this.card.lastfour
        //     } else {
        //         return i18n.t('directPay.not-set-credit')
        //     }
        // },
        // updateCard() {
        //     if (!this.editCard) {
        //         this.editCard = true
        //         this.editCardBtn = i18n.t('directPay.cancel')
        //         // this.isCardValid = false
        //     } else {
        //         this.editCard = false
        //         this.editCardBtn = i18n.t('directPay.change')
        //         // this.isCardValid = this.card && !this.editCard
        //     }
        // },
        submit() {
            setTimeout(() => {
                this.payByPrime()
            }, 10)

            // if (this.editCard || !this.card) this.payByPrime()
            // else this.payByToken()
        }
    }
    // mounted() {
        // this.setUpTPFields()
    // }
}
</script>

<style scoped>
.tpfield {
    height: 40px;
    width: 100%;
    border: 1px solid gray;
    margin: 5px 0;
    padding: 5px;
}
</style>
