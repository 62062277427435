import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import router from '@/router'
import { getAxiosConfig, encrypt, decrypt, PWA_TOKEN } from '@/utils/AuthService'
import { HOMETYPES } from '@/utils/utils'
import { redirectLogin } from '@/router'
import i18n from '@/i18n'

Vue.use(Vuex);

const LOCAL_TOKEN_KEY = 'auth'
const LOCAL_USER_ID_KEY = 'user'
export const LOCAL_HOME_KEY = 'pagehome'
function getUri() {
    //console.log('google login')
    const defaultPathRegrex = `\\/${i18n.locale}\\/?$`;
    const pathRegrex = `\\/storehome/?$`;
    const washRegrex = `\\/washhome/?$`;
    const traveldocsPathRegrex = `\\/${i18n.locale}\\/traveldocshome\\/?$`;
    let path = location.href
    if (location.href.indexOf('?') !== -1) {
        path = location.href.slice(0, location.href.indexOf("?"))
    }
    if (path.match(new RegExp(washRegrex,'g'))) {
        localStorage.setItem(LOCAL_HOME_KEY, HOMETYPES.WASH)
        return `/washhome`
    } else if (path.match(new RegExp(traveldocsPathRegrex,'g'))) {
        localStorage.setItem(LOCAL_HOME_KEY, HOMETYPES.TRAVELDOCS)
        return `/traveldocshome`
    } else if (path.match(new RegExp(pathRegrex,'g'))) {
        localStorage.setItem(LOCAL_HOME_KEY, HOMETYPES.STORE)
        return `/storehome`
    } else {
        let pp = path + `/${i18n.locale}/`
        if (pp.match(new RegExp(defaultPathRegrex,'g'))) {
            localStorage.setItem(LOCAL_HOME_KEY, HOMETYPES.LOCKER)
            return ''
        } else return 'ERROR'
    }
}

function getEnv() {
    switch (location.hostname) {
        case 'bboxlocker.com':
            return 'bbox'
        //case 'localhost':
        //    return 'local'
        default:
            return 'bbox'
    }
}
export const HostEnv = getEnv()
export const CurrentUri = getUri()
export const DoorCode = '?q=U2F'
export const UrlRedirectCode = '?openExternalBrowser'

export function routePage(page) {
    console.log('route home')
    if (page === HOMETYPES.STORE) {
        router.push(`/${i18n.locale}/storehome`)
    } else if (page === HOMETYPES.WASH) {
        router.push(`/${i18n.locale}/washhome`)
    } else if (page === HOMETYPES.TRAVELDOCS) {
        router.push(`/${i18n.locale}/traveldocshome`)
    } else {
        router.push(`/${i18n.locale}/`)
    }
}

export function qrcodeRoute(value) {
    let home = parseInt(localStorage.getItem('pagehome'))
    console.log('home:'+home+', value:'+value+", indexof:"+value.indexOf("traveldocshome"))
    if (home !== 3 && value && value.indexOf("traveldocshome") >= 0) {
        localStorage.setItem('pagehome', 3)
        localStorage.setItem('lineState', 'traveldocshome')
        if (/^09\d{8}$/.test(store.getters.user.phone)) {
            routePage(3)
            return true
        } else {
            store.commit('setUserId', 0)
            store.commit('setUserToken', '')
            store.commit('setUserProfile', { name: '', phone: '', email: '' })
            router.push(`/${i18n.locale}/login`)
            return true
        }
    } else if (home !== 2 && value && value.indexOf("washhome") >= 0) {
        localStorage.setItem('pagehome', 2)
        localStorage.setItem('lineState', 'washhome')
        if (/^09\d{8}$/.test(store.getters.user.phone)) {
            routePage(2)
            return true
        } else {
            store.commit('setUserId', 0)
            store.commit('setUserToken', '')
            store.commit('setUserProfile', { name: '', phone: '', email: '' })
            router.push(`/${i18n.locale}/login`)
            return true
        }
    } else if (home !== 1 && value && value.indexOf("storehome") >= 0) {
        localStorage.setItem('pagehome', 1)
        localStorage.setItem('lineState', 'storehome')
        routePage(1)
        return true
    } else if (home !== 0 && value && value.indexOf("storehome") < 0 && value.indexOf("washhome") < 0 && value.indexOf("traveldocshome") < 0) {
        localStorage.setItem('pagehome', 0)
        localStorage.setItem('lineState', '')
        routePage(0)
        return true
    }
    return false
}

function getHostUrl () {
    switch (location.hostname) {
        //case 'localhost':
        //    return 'http://192.168.0.7:5000'
        case 'bboxlocker.com':
            return 'https://bboxlocker.com:8001'
        default: //https://iboxmessage.com:8001
            return 'https://bboxlocker.com:8001'
    }
}
export const HostUrl = getHostUrl()

export const SideBtnType = {
    Navi: 0,
    Back: 1,
    Hide: 2
}
function PageLogout() {
    if (localStorage.getItem(LOCAL_TOKEN_KEY))
        localStorage.removeItem(LOCAL_TOKEN_KEY)
    if (localStorage.getItem(LOCAL_USER_ID_KEY))
        localStorage.removeItem(LOCAL_USER_ID_KEY)
    store.commit('setUserId', 0)
    store.commit('setUserToken', '')
    store.commit('setUserProfile', { name: '', phone: '', email: '' })
}
function sleep(sec) {
    var start = new Date().getTime();
    let mark = false
    while(mark) {
        if ((new Date().getTime() - start) > sec) {
            mark = true
            break;
        }
    }
}
function DetectHrefReload(sec) {
    let i =0
    while (i < 10) {
        if (location.href.indexOf('login') !== -1) {
            sleep(sec)
            i += 1
        } else {
            setTimeout(window.location.reload(), 1000)
            break
        }
    }
}
const store = new Vuex.Store({
    state: {
        title: 'BBox',
        clientId: Date.now(),
        pageHome: 0,
        user: {
            id: 0,
            token: '',
            phone: '',
            name: '',
            email: '',
            status: 0,
            hasPassword: '',
            isDocsClerk: false,
            isWashClerk: false,
            isStoreHost: false,
            isLogistics: false,
            isService: false,
            isStaff: false,
            isAdmin: false,
            isGuest: false,
            synced: false,
            box_owner: null,
            invoice: null
        },
        snackBar: {
            message: '',
            color: ''
        },
        mqttMessage: null,
        mqttPayload: null,
        controlResult: null,
        doorCheckResult: null,
        fromViews: '',
        boxSettingResult: null,
        boxStatusResult: null,
        targetBoxId: '',
        lastMessageTime: 0,
        sideBtnType: SideBtnType.Hide,
        loginRedirect: `/${i18n.locale}`,
        fromPath: `/${i18n.locale}`,
        updateAvailable: false,
        reopenTimeLimit: false,
        isFromLinePay: false,
        userInfo: null,
        showLinePayWarning: false,
        jkoPayParameter: null,
        linePayParameter: null,
        electronicMoneyZeroMark: null
    },
    mutations: {
        setTitle(state, title) {
            state.title = title;
        },
        setUserId(state, id) {
            let current = localStorage.getItem(LOCAL_USER_ID_KEY)
            if (id >= 0) {
                state.user.id = id;
                let encryptedUserId = encrypt(id.toString());
                if (current !== encryptedUserId) {
                    localStorage.setItem(LOCAL_USER_ID_KEY, encryptedUserId)
                }
            }
            if (id > 0) state.sideBtnType = SideBtnType.Navi;
        },
        setUserToken(state, token) {
            let current = localStorage.getItem(LOCAL_TOKEN_KEY)
            if (current != token)
                localStorage.setItem(LOCAL_TOKEN_KEY, token);
            state.user.token = decrypt(token);
        },
        setUserProfile(state, profile ) {
            state.user.phone = profile.phone
            state.user.name = profile.name
            state.user.email = profile.email
            state.user.status = profile.status
            state.user.hasPassword = profile.password
            state.user.isService = 'role' in profile ? (profile.role.is_service) : false
            state.user.isDocsClerk = 'role' in profile ? (profile.role.is_admin || profile.role.is_staff || profile.role.is_docsclerk) : false
            state.user.isWashClerk = 'role' in profile ? (profile.role.is_admin || profile.role.is_staff || profile.role.is_washclerk) : false
            state.user.isStoreHost = 'role' in profile ? (profile.role.is_admin || profile.role.is_staff || profile.role.is_storehost) : false
            state.user.isLogistics = 'role' in profile ? (profile.role.is_admin || profile.role.is_staff || profile.role.is_logistic) : false
            state.user.isStaff = 'role' in profile ? (profile.role.is_admin || profile.role.is_staff) : false
            state.user.isAdmin = 'role' in profile ? profile.role.is_admin : false
            state.user.isGuest = 'role' in profile ? (!profile.role.is_admin & !profile.role.is_staff & !profile.role.is_logistic & !profile.role.is_storehost & !profile.role.is_washclerk & !profile.role.is_service) : false
            state.user.box_owner = profile.box_owner
            state.user.invoice = profile.invoice
            state.user.synced = true
        },
        setSnackBar(state, snackBar) {
            state.snackBar = snackBar;
        },
        setMQTTMessage(state, message) {
            state.mqttMessage = message
        },
        setMQTTPayload(state, payload) {
            state.mqttPayload = payload
        },
        setControlResult(state, result) {
            state.controlResult = result
        },
        setDoorCheckResult(state, result) {
            state.doorCheckResult = result
        },
        setBoxSettingResult(state, result) {
            state.boxSettingResult = result
        },
        setBoxStatusResult(state, result) {
            state.boxStatusResult = result
        },
        setTargetBoxId(state, boxId) {
            let old = state.targetBoxId
            if (old != boxId)
                state.targetBoxId = boxId
        },
        setLastMessageTime(state, time) {
            state.lastMessageTime = time
        },
        initDoorCheck(state) {
            state.targetBoxId = '';
            state.doorCheckResult = null;
        },
        initDoorLock(state){
            state.targetBoxId = '';
            state.doorCheckResult = null;
        },
        initTransaction(state) {
            state.targetBoxId = '';
            state.controlResult = null;
        },
        setFromViews(state, view) {
            state.fromViews = view;
        },
        setSideBtnType(state, type) {
            state.sideBtnType = type;
        },
        setLoginRedirect(state, redirect) {
            state.loginRedirect = redirect || `/${i18n.locale}`;
        },
        setFromPath(state, path) {
            state.fromPath = path
        },
        setUpdateAvailable(state, isAvailable) {
            state.updateAvailable = isAvailable
        },
        setClientId(state) {
            console.log('setClientId')
            state.clientId = Date.now()
        },
        setPageHome(state, page) {
            console.log('setPageHome:'+state.pageHome+'->page:'+page)
            state.pageHome = page
            let current = parseInt(localStorage.getItem(LOCAL_HOME_KEY))
            if (current !== page) {
                console.log('change:'+current +' -> '+page)
                localStorage.setItem(LOCAL_HOME_KEY, page)
            }
        },
        setReopenTimeLimit(state, isLimit) {
            let ori = state.reopenTimeLimit
            state.reopenTimeLimit = isLimit
            if (ori != true && isLimit == true) {
                state.mqttPayload = new Date().getTime()
            } else if (isLimit == false) {
                state.mqttPayload = null
            }
        },
        setIsFromLinePay(state, boolean) {
            state.isFromLinePay = boolean
        },
        setUserInfo(state, info) {
            state.userInfo = info
        },
        setShowLinePayWarning(state, boolean) {
            state.showLinePayWarning = boolean
        },
        setJkoPayParameter(state, object) {
            state.jkoPayParameter = object
        },
        setLinePayParameter(state, object) {
            state.linePayParameter = object
        },
        setElectronicMoneyZeroMark(state, el_mark) {
            state.electronicMoneyZeroMark = el_mark
        }
    },
    actions: {
        getUserProfile({ state, commit }) {
            console.log('[userprofile]:'+location.href)
            let userId = localStorage.getItem(LOCAL_USER_ID_KEY);
            let token = localStorage.getItem(LOCAL_TOKEN_KEY);
            let unloginflag = localStorage.getItem('unloginflag')
            if (!userId || !token) return;
            userId = decrypt(userId);
            if (state.user.id === parseInt(userId) && token) return;
            else {
                console.log('diff user:'+state.user.id+'local:'+userId)
                if (unloginflag && unloginflag === 'wash-takeout') {
                    localStorage.setItem('unloginflag', 'reload-wash-takeout')
                } else if (unloginflag && unloginflag === 'traveldocs-takeout') {
                    localStorage.setItem('unloginflag', 'reload-traveldocs-takeout')
                }
            }
            commit('setUserId', parseInt(userId));
            commit('setUserToken', token);
            axios.get(HostUrl + '/api/user/profile', getAxiosConfig())
                .then(function (response) {
                    if (response.data.code === 0) {
                        let profile = response.data.profile
                        commit('setUserProfile', profile)
                    } else {
                        commit('setSnackBar', {
                            message: response.data.message,
                            color: 'error'
                        })
                    }
                })
                .catch(function (error) {
                    console.error('get profile failed', error)
                })
        },
        afterLogin({ state, commit }) {
            let linestate = localStorage.getItem('lineState')
            console.log('afterlogin:'+i18n.locale+'linestate:'+linestate)
            if (state.pageHome == HOMETYPES.STORE) {
                commit('setLoginRedirect', `/${i18n.locale}/storehome`);
                commit('setFromPath', `/${i18n.locale}/storehome`)
            }
            else if (state.pageHome == HOMETYPES.WASH) {
                commit('setLoginRedirect', `/${i18n.locale}/washhome`);
                commit('setFromPath', `/${i18n.locale}/washhome`)
            }
            else if (state.pageHome == HOMETYPES.TRAVELDOCS) {
                commit('setLoginRedirect', `/${i18n.locale}/traveldocshome`);
                commit('setFromPath', `/${i18n.locale}/traveldocshome`)
            }
            else {
                commit('setLoginRedirect', `/${i18n.locale}`);
                commit('setFromPath', `/${i18n.locale}`)
            }
            console.log('redirect:' + state.loginRedirect)
            let to = state.loginRedirect;
            router.push(to);
            if (linestate) {
                localStorage.setItem('lineState', '')
                DetectHrefReload(2000)
            } else if (location.hostname !== 'localhost') {
                DetectHrefReload(1000)
            }
            //else {
            //    console.log('[after login] localhost not reload mark')
            //}
        },
        changeUser({ commit }, { profile, token }) {
            commit('setUserToken', token)
            commit('setUserProfile', profile)
        },
        async login({ commit, dispatch }, { username, password }) {
            let out = null
            try {
                let auth_code = null;
                if (username.includes(",")) {
                    let pp = username.split(",");
                    username = pp[0]
                    auth_code = pp[1]
                }
                let payload = {
                    username: username,
                    password: encrypt(password),
                    browser: navigator.userAgent
                }
                if (auth_code != null) {payload.auth_code=auth_code}
                let response = await axios.post(HostUrl + '/api/user/login', payload, getAxiosConfig(PWA_TOKEN));
                if (response.data.code == 0) {
                    commit('setUserId', response.data.profile.id)
                    dispatch('changeUser', {
                        profile: response.data.profile,
                        token: response.data.token
                    })
                    dispatch('afterLogin')
                    out = false
                } else {
                    if (response.data.message === '密碼不正確') {
                        response.data.message = i18n.t('store.pass-incorrect')
                    }
                    if (response.data.message === '請註冊後使用') {
                        response.data.message = i18n.t('store.register-first')
                        router.push(`/${i18n.locale}/register`)
                    }
                    commit('setUserId', 0)
                    commit('setSnackBar', {
                        message: response.data.message,
                        color: 'error'
                    })
                    out = true
                }
            } catch (error) {
                commit('setSnackBar', {
                    message: i18n.t('store.login-fail'),
                    color: 'error'
                })
                out = true
            }
            if (out) {
                this.$router.push(`/${i18n.locale}/login`)
            }
        },
        async socialLogin({ commit, dispatch }, { social_id, name, email, social, info, token}) {
            let out = null
            try {
                let payload = {
                    social_id: social_id,
                    name: name,
                    email: email,
                    social: social,
                    info: info,
                    token: token,
                    browser: navigator.userAgent
                }
                let response = await axios.post(HostUrl + '/api/user/social_login', payload
                , getAxiosConfig(PWA_TOKEN));
                if (response.data.code == 0) {
                    commit('setUserId', response.data.profile.id)
                    dispatch('changeUser', {
                        profile: response.data.profile,
                        token: response.data.token
                    })
                    dispatch('afterLogin')
                    out = false
                } else {
                    commit('setUserId', 0)
                    commit('setSnackBar', {
                        message: response.data.message,
                        color: 'error'
                    })
                    out = true
                }
            } catch (error) {
                commit('setSnackBar', {
                    message: i18n.t('store.login-fail'),
                    color: 'error'
                })
                out = true
            }
            if (out) {
                this.$router.push(`/${i18n.locale}/login`)
            }
        },
        transactionIdLogin({ commit, dispatch }, { transactionId, payment_type, client_id }) {            
            return new Promise(async (resolve, reject) => {
                try {
                    let response = await axios.post(HostUrl + '/api/user/transactionid_login', {
                        transactionId: transactionId,
                        payment_type: payment_type,
                        client_id: client_id
                    }, getAxiosConfig(PWA_TOKEN))                    
                    if (response.data.code == 0 || response.data.code == 508) {
                        commit('setUserId', response.data.profile.id)
                        dispatch('changeUser', {
                            profile: response.data.profile,
                            token: response.data.token
                        })
                        resolve()
                    } else {
                        commit('setUserId', 0)
                        commit('setSnackBar', {
                            message: response.data.message,
                            color: 'error'
                        })
                        reject(response.data.message)
                    }
                } catch(error) {
                    commit('setSnackBar', {
                        message: i18n.t('store.login-fail'),
                        color: 'error'
                    })
                    reject(error)
                }
            })
        },
        facebookInit() {
            //防止重複載入
            //測試：appId      : '2650128821965359'
            //正式：appId      : '787967835095315'
            if (!window.FB) {
                window.fbAsyncInit = function() {
                    //eslint-disable-next-line
                    FB.init({
                        appId      : '787967835095315',
                        cookie     : true,
                        xfbml      : true,
                        version    : 'v17.0'
                    });
                };

                (function(d, s, id){
                    var js, fjs = d.getElementsByTagName(s)[0];
                    if (d.getElementById(id)) {return;}
                    js = d.createElement(s); js.id = id;
                    js.src = "https://connect.facebook.net/en_US/sdk.js";
                    fjs.parentNode.insertBefore(js, fjs);
                }(document, 'script', 'facebook-jssdk'));
            }
        },
        logout() {
            //檢查登入狀態
            const pageHome = this.state.pageHome
            //eslint-disable-next-line
            if (window.FB) {
                window.FB.getLoginStatus(function(response) {
                    if (response.status === "connected") {
                        //移除授權
                        //eslint-disable-next-line
                        // FB.api("/me/permissions", "DELETE", function(response) {
                        //     console.log('response', response)
                        // })
                    
                        //用戶登出
                        //eslint-disable-next-line
                        FB.logout(function(response){
                            PageLogout()
                        })
                    }
                })
            }
            routePage(pageHome)
            PageLogout()
            setTimeout(window.location.reload(), 1000)
        },
        clientIdAction({ commit }) {
            console.log('clientIdAction')
            commit('setClientId')
        }
        // setLocale({ commit }) {
        //     commit()
        // }
    },
    getters: {
        title: state => state.title,
        snackBar: state => state.snackBar,
        user: state => state.user,
        clientId: state => state.clientId,
        mqttMessage: state => state.mqttMessage,
        mqttPayload: state => state.mqttPayload,
        controlResult: state => state.controlResult,
        doorCheckResult: state => state.doorCheckResult,
        fromViews: state => state.fromViews,
        boxSettingResult: state => state.boxSettingResult,
        boxStatusResult: state => state.boxStatusResult,
        targetBoxId: state => state.targetBoxId,
        lastMessageTime: state => state.lastMessageTime,
        sideBtnType: state => state.sideBtnType,
        loginRedirect: state => state.loginRedirect,
        fromPath: state => state.fromPath,
        updateAvailable: state => state.updateAvailable,
        reopenTimeLimit: state => state.reopenTimeLimit,
        isFromLinePay: state => state.isFromLinePay,
        userInfo: state => state.userInfo,
        showLinePayWarning: state => state.showLinePayWarning,
        jkoPayParameter: state => state.jkoPayParameter,
        linePayParameter: state => state.linePayParameter,
        electronicMoneyZeroMark: state => state.electronicMoneyZeroMark,
        pageHome: state => state.pageHome,
    }
})

// set up axios interceptors
axios.interceptors.response.use(
    response => {
        if (response.data.message === "信用卡支付服務異常，請選擇其他支付方式"){
            response.data.message = i18n.t('store.operate-time-out')
        } else if (response.data.message === "帳號不存在或未通過驗證") {
            response.data.message = i18n.t('store.account-not-exist')
        } 
        console.log('API OK: ', response)
        return response;
    }, error => {
        console.log('API Error:', error)
        if (error.response != undefined && error.response.status === 401) {
            PageLogout()
            redirectLogin()
        }
        return Promise.reject(error)
    }
);

export default store;
