<template>
    <span>
        <v-navigation-drawer app v-model="drawer" class="blue-grey lighten-5" disable-resize-watcher>
            <v-list>
                <template v-for="(item, index) in filterItems">
                    <v-list-tile :key="index" :to="`/${$i18n.locale}${item.route}/`">
                        <v-list-tile-title :class="[index === 0 ? 'title' : 'subheading']">
                            <b>{{ $t(item.title) }}</b>
                        </v-list-tile-title>
                    </v-list-tile>
                    <v-divider :key="`divider-${index}`"></v-divider>
                </template>
            </v-list>
        </v-navigation-drawer> 
        <v-toolbar app color="blue darken-4" dark flat>
            <v-toolbar-side-icon v-show="isNavi" class="hidden-md-and-up" @click="drawer = !drawer"></v-toolbar-side-icon>
            <v-icon v-show="isBack" @click="back()">arrow_back_ios</v-icon>
            <v-toolbar-title :class="[{'hidden-md-and-up': atMainPage() && isLogin()}]">{{ $t(appTitle) }}</v-toolbar-title>
            <template v-for="(item, index) in filterItems">
                <v-btn
                    flat
                    class="hidden-sm-and-down text-none"
                    :class="[index === 0 ? 'headline' : 'subheading']"
                    :key="index"
                    :to="`/${$i18n.locale}${item.route}/`"
                ><span>{{ $t(item.title) }}</span></v-btn>
            </template>
            <LanguageSwitcher v-if="showLangSwitcher()"/>
            <template v-if="showLogin()">
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" :to="`/${$i18n.locale}/login/`">{{ $t('nav.login') }}</v-btn>
            </template>
        </v-toolbar>
    </span>
</template>

<script>
import store from '@/store'
import { SideBtnType, routePage } from '@/store'
import i18n from '@/i18n'
import LanguageSwitcher from './LanguageSwitcher'
import { HOMETYPES } from '@/utils/utils'

const Permission = {
    Admin: 7,
    Storehost: 5,
    Washclerk: 4,
    Docsclerk: 3,
    Staff: 6,
    Logistics: 2,
    Service: 1,
    Guest: 0,
    Anonymous: -1
}

const redirectPage = (item, pageHome)=> {
    if (pageHome === HOMETYPES.STORE) {
        item.route = "/storehome";
    } else if (pageHome === HOMETYPES.WASH) {
        item.route = "/washhome"
    } else if (pageHome === HOMETYPES.TRAVELDOCS) {
        item.route = "/traveldocshome"
    } else {
        item.route = "";
    }
}

export default {
    name: 'AppNavigation',
    data() {
        return {
            appTitle: '',
            drawer: false,
            items: [
                { title: 'nav.items.b-box', route: '', perm: Permission.Anonymous },
                { title: 'nav.items.staff', route: '/staff/menu', perm: Permission.Staff },
                { title: 'nav.items.logistics', route: '/logistics/menu', perm: Permission.Logistics },
                { title: 'nav.items.storehost', route: '/storehost/menu', perm: Permission.Storehost},
                { title: 'nav.items.washhost', route: '/washhost/menu', perm: Permission.Washclerk},
                { title: 'nav.items.docshost', route: '/docshost/menu', perm: Permission.Docsclerk},
                { title: 'nav.items.list', route: '/menu', perm: Permission.Guest },
                { title: 'nav.items.about', route: '/about', perm: Permission.Anonymous }
            ],
            sideBtnType: SideBtnType.Hide
        };
    },
    components: {
        LanguageSwitcher
    },
    computed: {
        isNavi() { 
            return this.sideBtnType === SideBtnType.Navi && this.filterItems.length > 1
        },
        // isNavi() { return this.sideBtnType === SideBtnType.Navi && this.filterItems().length > 1 },
        isBack() {
            return this.sideBtnType === SideBtnType.Back 
        },
        filterItems() {
            const user = this.$store.getters.user;
            console.debug(user);
            const pageHome = this.$store.getters.pageHome;
            this.items.filter(a=>a.title === "nav.items.b-box").forEach(item=>redirectPage(item, pageHome));
            if (this.sideBtnType !== SideBtnType.Navi) return []
            let items = []
            if (user.isAdmin) {
                items = this.items.filter(item => item.perm <= Permission.Admin)
            } else if (user.isStaff) {
                items = this.items.filter(item => item.perm <= Permission.Staff)
            } else if (user.isService) {
                items = this.items.filter(item => (item.perm <= Permission.Service || item.perm == Permission.Staff))
            } else if (user.isLogistics && user.isStoreHost) {
                if (pageHome === HOMETYPES.STORE) {
                    const excludePermits=[Permission.Staff];
                    items = this.items.filter(item => (item.perm <= Permission.Storehost && !excludePermits.includes(item.perm)));
                } else {
                    const excludePermits=[Permission.Staff,Permission.Logistics];
                    items = this.items.filter(item => (item.perm <= Permission.Storehost && !excludePermits.includes(item.perm)));
                }
            } else if (user.isLogistics && user.isWashClerk) {
                if (pageHome == HOMETYPES.WASH) {
                    const excludePermits=[Permission.Staff];
                    items = this.items.filter(item => (item.perm <= Permission.Storehost && !excludePermits.includes(item.perm)));
                } else {
                    const excludePermits=[Permission.Staff,Permission.Logistics];
                    items = this.items.filter(item => item.perm <= Permission.Washclerk && !excludePermits.includes(item.perm));
                }
            } else if (user.isLogistics && user.isDocsClerk) {
                if (pageHome == HOMETYPES.TRAVELDOCS) {
                    const excludePermits=[Permission.Staff];
                    items = this.items.filter(item => (item.perm <= Permission.Docsclerk && !excludePermits.includes(item.perm)));
                } else {
                    const excludePermits=[Permission.Staff,Permission.Logistics];
                    items = this.items.filter(item => item.perm <= Permission.Docsclerk && !excludePermits.includes(item.perm));
                }
            } else if (user.isLogistics) {
                items = this.items.filter(item => item.perm <= Permission.Logistics)
            } else if (user.isStoreHost && pageHome === HOMETYPES.STORE) {
                const excludePermits=[Permission.Staff,Permission.Logistics];
                items = this.items.filter(item => (item.perm <= Permission.Storehost && !excludePermits.includes(item.perm)));
            } else if (user.isWashClerk && pageHome === HOMETYPES.WASH) {
                const excludePermits=[Permission.Staff,Permission.Logistics];
                items = this.items.filter(item => item.perm <= Permission.Washclerk && !excludePermits.includes(item.perm));
            } else if (user.isWashClerk && pageHome === HOMETYPES.TRAVELDOCS) {
                const excludePermits=[Permission.Staff,Permission.Logistics];
                items = this.items.filter(item => item.perm <= Permission.Docsclerk && !excludePermits.includes(item.perm));
            } else if (user.id>0) {
                items = this.items.filter(item => item.perm <= Permission.Guest)
            } else {
                items = this.items.filter(item => item.perm <= Permission.Anonymous)
            }
            // 如果進來的首頁是home的話、不show(店家專區 + 洗衣專區)
            if (pageHome === HOMETYPES.LOCKER) {
                items = items.filter(a=>(a.title !== "nav.items.storehost" && a.title !== "nav.items.washhost" && a.title !== "nav.items.docshost" ));
            } else if (pageHome == HOMETYPES.STORE) {
                items = items.filter(a=>(a.title !== "nav.items.washhost" && a.title != "nav.items.docshost" ));
            } else if (pageHome == HOMETYPES.WASH) {
                items = items.filter(a=>(a.title !== "nav.items.storehost" && a.title != "nav.items.docshost" ));
            } else if (pageHome == HOMETYPES.TRAVELDOCS) {
                items = items.filter(a=>(a.title !== "nav.items.storehost" && a.title !== "nav.items.washhost" ));
            }
            return items.length > 1 ? items : [];
        },
    },
    methods: {
        atMainPage() {
            let path = this.$router.currentRoute.fullPath
            for (let i = 0; i < this.items.length; i++) {
                if (path === ('/' + i18n.locale + this.items[i].route) + '/') return true
            }
            return false
        },
        atHomePage(){
            const defaultPathRegrex = `\\/${i18n.locale}\\/?$`;
            const pathRegrex = `\\/${i18n.locale}\\/storehome\\/?$`;
            const washPathRegrex = `\\/${i18n.locale}\\/washhome\\/?$`;
            const traveldocsPathRegrex = `\\/${i18n.locale}\\/traveldocshome\\/?$`;
            if( this.$router.currentRoute.fullPath.match(new RegExp(pathRegrex,'g')) ) return true;
            else if (this.$router.currentRoute.fullPath.match(new RegExp(washPathRegrex,'g')) ) return true;
            else if (this.$router.currentRoute.fullPath.match(new RegExp(traveldocsPathRegrex,'g')) ) return true;
            else if(this.$router.currentRoute.fullPath.match(new RegExp(defaultPathRegrex,'g'))) return true;
            return false;
        },
        showLangSwitcher() {
            if(this.atHomePage()) return true;
            return false;
        },
        showLogin() {
            if (!this.isLogin() && this.atHomePage()){
                return true
            }
            return false;
        },
        isLogin() {
            return store.getters.user.id > 0
        },
        back() {
            // let internalLink = document.referrer.indexOf(location.protocol + "//" + location.host) === 0
            // if (store.getters.fromPath === `/${i18n.locale}/` || internalLink === false) this.$router.replace(`/${i18n.locale}/`)
            // else this.$router.back()
            let pagehome = store.getters.pageHome
            let uri = location.href
            if(store.getters.isFromLinePay) {
                routePage(pagehome)
                store.commit('setIsFromLinePay', false)
            } else {
                if (uri && uri.indexOf('staff') !== -1) {
                    this.$router.push(`/${i18n.locale}/staff/menu`)
                } else if (uri && uri.indexOf('logistics') !== -1) {
                    this.$router.push(`/${i18n.locale}/logistics/menu`)
                } else if (uri && uri.indexOf('docshost') !== -1 && uri.indexOf('clerk') !== -1) {
                    this.$router.push(`/${i18n.locale}/docshost/menu`)
                } else if (uri && uri.indexOf('washhost') !== -1 && uri.indexOf('clerk') !== -1) {
                    this.$router.push(`/${i18n.locale}/washhost/menu`)
                } else if (uri && uri.indexOf('storehost') !== -1 && (uri.indexOf('putin') !== -1 || uri.indexOf('retrieve') !== -1)) {
                    this.$router.push(`/${i18n.locale}/storehost/menu`)
                } else if(window.history.length > 1 && store.getters.fromPath === '/') {
                    routePage(pagehome)
                } else {
                    this.$router.back()
                }
            }
        }
    },
    mounted() {
        this.appTitle = store.getters.title;
        store.watch(
            (state, getters) => { 
                return getters.title 
            },
            (newValue) => { this.appTitle = newValue; }
        )
        this.sideBtnType = store.getters.sideBtnType;
        store.watch(
            (state, getters) => getters.sideBtnType,
            (newValue) => { this.sideBtnType = newValue; }
        )
    }
};
</script>

<style scoped>
a {
    color: white;
    text-decoration: none;
}
</style>
