<template>
    <v-card flat>
        <v-card-text>
                {{ $t('jkoPay.use-line-pay') }} <strong>{{ $t('jkoPay.login-close-tab') }}</strong> {{ $t('jkoPay.continue-complete-payment') }}
            <v-alert :value="message" type="warning">{{ message }}</v-alert>
        </v-card-text>
        <waiting-dialog :show.sync="dialog.show" :msg="dialog.msg" :err-msg="dialog.err"></waiting-dialog>
    </v-card>
</template>

<script>
import WaitingDialog from '@/components/WaitingDialog'
import WaitDialogControl from '@/mixins/WaitDialogControl'
import i18n from '@/i18n'

export const JKOPayType = 4 // 配合 Backend 定義
export default {
    name: 'jkopay',
    props: {
        enable: {
            type: Boolean,
            required: true,
        },
        message: {
            type: String,
            default: ''    
        }
    },
    components: { WaitingDialog },
    mixins: [ WaitDialogControl ],
    watch: {
        enable() {
            if (this.enable) this.submit()
            else this.$emit('payinfo', null)
        }
    },
    methods: {
        payByPrime() {
            this.$emit('payinfo', {
                type: JKOPayType,
                locale: i18n.locale
            })
        },
        submit() {
            this.payByPrime()
        }
    }
}
</script>

<style scoped>

</style>
